<template>
  <UiPopup
    :model-value="modelValue"
    size="mediumBig"
    class="bg-black-100/50"
    popup-classes="!p-0 [&>div]:p-0"
    hidden
    @update:model-value="emits('update:modelValue', false)"
  >
    <template #header>
      <button class="absolute right-8 top-8" @click="emits('update:modelValue', false)">
        <UiIcon name="big-close"></UiIcon>
      </button>
    </template>
    <div class="flex h-[437px]">
      <div class="w-1/2">
        <client-only>
          <Swiper navigation :modules="[SwiperNavigation]" class="!h-full">
            <SwiperSlide v-for="(image, i) in gallery" :key="i" class="!size-full cursor-pointer select-none">
              <img :src="image" :alt="`preview-${i}`" class="size-full object-contain" />
            </SwiperSlide>
          </Swiper>
        </client-only>
      </div>
      <div class="flex h-full w-1/2 flex-1 flex-col bg-black-03 p-6 text-left">
        <div class="mb-8 flex gap-2">
          <PropertyTabsUnitTag
            :label="item.rooms_count.en.length ? item.rooms_count.en : PROPERTIES_ROOMS_MAP[item.key]"
          />
          <PropertyTabsUnitTag v-if="item.type.en.length" :label="item.type.en" />
        </div>
        <h4 class="mb-2.5">{{ item.rooms_count.en.length ? item.rooms_count.en : item.title.en }}</h4>
        <div class="text-body mb-1 flex items-center gap-2">
          <p>Type {{ index + 1 }}</p>
          •
          <p>{{ item.sale_units_count }} units</p>
          •
          <p>
            from {{ item.area_min[getUserMeasure] }}
            {{ useGetUserMeasure() }}
          </p>
        </div>
        <p v-if="item.price.min.user && item.price.max.user" class="text-subhead-2">
          {{ authStore.getUserCurrencyCode }} ~{{ aedFormatter.format(Number(item.price.min.user?.value)) }} -
          {{ authStore.getUserCurrencyCode }} ~{{ aedFormatter.format(Number(item.price.max.user?.value)) }}
        </p>
        <p class="text-subhead-2 mb-6">
          AED {{ aedFormatter.format(Number(item.price.min.base.value)) }} - AED
          {{ aedFormatter.format(Number(item.price.max.base.value)) }}
        </p>
        <div class="flex gap-8">
          <div class="flex gap-2">
            <div class="size-10 rounded bg-white p-2">
              <UiIcon name="area"></UiIcon>
            </div>
            <div>
              <p class="text-caption mb-px text-black-60">Total S</p>
              <p class="text-subhead-2">{{ item.area_min[getUserMeasure] }} {{ useGetUserMeasure() }}</p>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="size-10 rounded bg-white p-2">
              <UiIcon name="Bed"></UiIcon>
            </div>
            <div>
              <p class="text-caption mb-px text-black-60">Rooms</p>
              <p class="text-subhead-2">
                {{ item.rooms_count.en.length ? item.rooms_count.en : PROPERTIES_ROOMS_MAP[item.key] }}
              </p>
            </div>
          </div>
          <div class="flex gap-2">
            <div class="size-10 rounded bg-white p-2">
              <UiIcon name="Balcony"></UiIcon>
            </div>
            <div>
              <p class="text-caption mb-px text-black-60">Balcony S</p>
              <p class="text-subhead-2">
                {{
                  item.area_balcony_min[getUserMeasure].length
                    ? item.area_balcony_min[getUserMeasure] + useGetUserMeasure()
                    : '-'
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-auto flex gap-2">
          <PropertyTabsUnitTag dark :label="complex.sales_status.en" />
          <PropertyTabsUnitTag dark :label="isNotFinished ? 'Not finished' : 'Finished'" />
          <PropertyTabsUnitTag
            v-if="complex.planned_completion_at"
            dark
            :label="`Planned for ${format(new Date(complex.planned_completion_at), 'QQQ yyyy')}`"
          />
        </div>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { format, isFuture } from 'date-fns'
import type { PropertyTypes } from '@/types'
import { PROPERTIES_ROOMS_MAP, MEASURE_LIST_LABELS } from '@/constants'
import { useAuthStore } from '~/store/auth'

const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue'])

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

type Props = {
  modelValue: boolean
  item: PropertyTypes.Apartment
  index: number
  complex: PropertyTypes.PropertyDetailed
}

const props = defineProps<Props>()

const gallery = computed(() => {
  return props.item.levels_photos.flatMap((item: PropertyTypes.Level) => item.image)
})

const isNotFinished = computed(() => {
  if (!props.complex.planned_completion_at) return false
  return isFuture(new Date(props.complex.planned_completion_at))
})

const getUserMeasure = computed(() => (useGetUserMeasure() === MEASURE_LIST_LABELS.METRIC ? 'm2' : 'ft2'))
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply bottom-6 mt-0 size-8 rounded-lg bg-black-05;
      top: initial !important;
      &:after {
        @apply text-lg text-black-100;
      }
      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }
    &-button-prev {
      @apply right-[72px];
      left: initial !important;
    }
    &-button-next {
      @apply right-6;
    }
  }
}
</style>
